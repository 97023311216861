exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-collection-all-plushies-js": () => import("./../../../src/pages/collection/all-plushies.js" /* webpackChunkName: "component---src-pages-collection-all-plushies-js" */),
  "component---src-pages-collection-cute-dragon-js": () => import("./../../../src/pages/collection/cute-dragon.js" /* webpackChunkName: "component---src-pages-collection-cute-dragon-js" */),
  "component---src-pages-collection-mini-cows-js": () => import("./../../../src/pages/collection/mini-cows.js" /* webpackChunkName: "component---src-pages-collection-mini-cows-js" */),
  "component---src-pages-collection-unique-teddy-js": () => import("./../../../src/pages/collection/unique-teddy.js" /* webpackChunkName: "component---src-pages-collection-unique-teddy-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-clown-bear-js": () => import("./../../../src/pages/products/clown-bear.js" /* webpackChunkName: "component---src-pages-products-clown-bear-js" */),
  "component---src-pages-products-grassland-cow-js": () => import("./../../../src/pages/products/grassland-cow.js" /* webpackChunkName: "component---src-pages-products-grassland-cow-js" */),
  "component---src-pages-products-magic-wool-sheep-js": () => import("./../../../src/pages/products/magic-wool-sheep.js" /* webpackChunkName: "component---src-pages-products-magic-wool-sheep-js" */),
  "component---src-pages-products-sleepy-dragon-js": () => import("./../../../src/pages/products/sleepy-dragon.js" /* webpackChunkName: "component---src-pages-products-sleepy-dragon-js" */),
  "component---src-pages-products-sunflower-chicken-js": () => import("./../../../src/pages/products/sunflower-chicken.js" /* webpackChunkName: "component---src-pages-products-sunflower-chicken-js" */),
  "component---src-pages-return-policy-js": () => import("./../../../src/pages/return-policy.js" /* webpackChunkName: "component---src-pages-return-policy-js" */)
}

