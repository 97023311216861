module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CartoonPlushies-Application","short_name":"CP-App","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/img/shared/web-branding/favicon64x64.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5a6f08e10960ee00988a5016d0e7f71f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.7.3","publicApiKey":"MzQ3NzJjOGUtYzk1NC00ZmYxLWI4NjYtZTc4NDRlODQ1MDkwNjM4MzQzNTA1MzEzNzY1MDA2","currency":"usd","openCartOnAdd":true,"useSideCart":true,"locales":{},"templatesUrl":"","innerHTML":""},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
